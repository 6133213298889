import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Body from "./components/Body";
import { preloadImages } from "./utils/preloadImages";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import "./App.css";

const contentArray = [
  {
    title: "DJ ÓK",
    text: "DJ ÓK is an Icelandic dj duo from Reykjavík. Elísa and Íris are old friends from high school that started dj-ing together on Friday mornings in Iceland’s cultural coffee mecca, Kaffifélagið. What started off as an ironic idea slowly became its own conceptually cohesive world, characterized by visual art, events and music. The duo works closely with Katrín Hersisdóttir who handles their graphics that visually support DJ ÓK’s universe.",
    images: [
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Djoklogo.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/djoklogobeast.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Djoklogoblue.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Djoklogocake.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/djoklogograve.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Djoklogobf.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/djokspace.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Screenshot+2024-07-31+at+12.02.11.png"
    ],
  },
  {
    title: "Kan(l)ínudans",
    text: "Kan(l)ínudans or Rabbit Erosion is an intricately choreographed dance theater performance intended for young audiences (4-12 years old) by Sóley Ólafsdóttir and Leevi Mettinen. The piece stages two bunnies who play games searching for connection. Katrín Hersisdóttir designed a social media advertisement/invitation and a brochure for the dance piece.",
    images: [
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Kanlinuhvitt.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/2sidekanlinu.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/kanlinucover2.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/kanlinucoverbackside.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/kanlinupink.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/kanlinucoverpurple.png",
    ],
  },
  {
    title: "Fyrir afa",
    text: "Fyrir afa, nokkrar smásögur is a collection of short stories written by Sigurgeir Jónsson. Katrín Hersisdóttir illustrated his stories and designed the book.",
    images: [
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Fyrirafagratt2.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/fyrirafa2hvitt.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Fyrirafa1gratt.png",
    ],
  },
  {
    title: "Character design",
    images: [
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/fjolublattkarakter.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/KatrinYellow.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Screenshot+2024-07-08+at+13.02.12+2.png",
    ],
  },
  {
    title: "Drawings",
    images: [
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Screenshot+2024-07-08+at+13.03.19.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Screenshot+2024-07-08+at+13.04.03.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Screenshot+2024-07-08+at+13.03.27.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/Screenshot+2024-07-08+at+13.02.37.png",
    ],
  },
  {
    title: "SOHER",
    text: "SOHER is a knit brand. Katrín Hersisdóttir designed the logo.",
    images: [
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/sohertags.png",
      "https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/KATRINBUCKET/soherlogo.png",
    ],
  },
];
function App() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);

  useEffect(() => {
    if (activeIndex !== null) {
      console.log(`Clicked Index: ${activeIndex}`);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (hoverIndex !== null) {
      console.log(`Hovered Index: ${hoverIndex}`);
    }
  }, [hoverIndex]);

  useEffect(() => {
    const allImages = contentArray.flatMap((item) => item.images);
    preloadImages(allImages)
      .then(() => console.log("All images preloaded"))
      .catch((error) => console.error("Error preloading images:", error));
  }, []);

  const footer = (
    <div className=" flex flex-col text-right ">
      <a
        href="mailto:katrinh@outlook.com?subject=Subject%20Here&body=Body%20text%20here"
        target="_blank"
        className="custom-cursor"
      >
        katrinh17@outlook.com
      </a>
      <a href="tel:+3547831830" target="_blank" className="custom-cursor ">
        +354 7831830
      </a>
      <a
        href="https://www.instagram.com/katrinhersis.exe/"
        target="_blank"
        className="custom-cursor"
      >
        @katrinhersis.exe
      </a>
    </div>
  );

  return (
    <div className="App flex flex-col sm:flex-row sm:gap-2 h-screen relative hide-scrollbar">
      <div className="flex pl-8 pt-4 flex-col sm:w-1/6 fixed w-full sm:relative z-10">
        <Header />
        <div className="flex flex-col ">
          <p className="underline underline-offset-3 mb-2 sm:mb-3">
            SELECTED WORKS
          </p>
          {contentArray.map((item, index) => (
            <div className="flex items-center " key={index}>
              <p
                onClick={() => {
                  setActiveIndex(index);
                  setHoverIndex(null);
                }}
                onMouseEnter={() => !isMobile && setHoverIndex(index)}
                onMouseLeave={() => !isMobile && setHoverIndex(null)}
                className={`custom-cursor cursor-default ${
                  activeIndex === index || hoverIndex === index
                    ? "underline underline-offset-3"
                    : "z-10"
                }`}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="relative sm:w-full sm:mr-3">
        {activeIndex !== null && (
          <Body
            key={`active-${activeIndex}`}
            images={contentArray[activeIndex].images}
            text={contentArray[activeIndex].text}
            clicked={true}
            hovered={false}
            footer={footer}
          />
        )}
        {hoverIndex !== null && hoverIndex !== activeIndex && (
          <Body
            key={`hover-${hoverIndex}`}
            images={contentArray[hoverIndex].images}
            text={contentArray[hoverIndex].text}
            clicked={false}
            hovered={true}
            footer={footer}
          />
        )}
        {activeIndex === null && hoverIndex === null && (
       
            <div className="fixed bottom-0 right-0 text-right pb-20 pr-16 mt-auto">
              {footer}
            </div>
  
        )}
      </div>
    </div>
  );
}

export default App;
