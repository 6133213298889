export const preloadImages = (imageUrls) => {
    return new Promise((resolve, reject) => {
      let loadedImages = 0;
      const totalImages = imageUrls.length;
  
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          loadedImages++;
          if (loadedImages === totalImages) {
            resolve();
          }
        };
        img.onerror = reject;
      });
    });
  };