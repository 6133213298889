import React, { useState, useEffect, useRef } from "react";
import "./../App.css";

const Body = ({ images, text, clicked, hovered, footer }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (clicked && scrollContainerRef.current) {
      console.log("Resetting scroll position");
      scrollContainerRef.current.scrollTop = 0;
      console.log("ScrollTop after reset:", scrollContainerRef.current.scrollTop);
    }
  }, [images, clicked]);

  const formatTextWithLineBreaks = (text) => {
    if (!text) return null;

    const sentences = text.split(/(?<=[.?!])\s+/);
    return sentences.map((sentence, index) => (
      <React.Fragment key={index}>
        {sentence}
        {index < sentences.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className="flex flex-col sm:flex-row sm:absolute h-full w-full hide-scrollbar">
      {/* Image section */}
      <div className={`pt-44 sm:pt-0 sm:w-3/5 h-fit ${clicked ? "sm:h-full sm:overflow-y-auto" : "h-full"} hide-scrollbar`}>
        {/* Show the first image */}
        <div className={`relative ${clicked || hovered ? "" : "opacity-0"} ${hovered ? "z-10" : ""} ${isScrolled ? "mt-0" : "mt-12"}`}>
          <img src={images[0]} alt="First Image" className="w-full h-full object-cover" />
        </div>
        
        {/* Only display the rest of the images if clicked */}
        {clicked && (
          <div className="flex flex-col overflow-y-scroll hide-scrollbar" ref={scrollContainerRef}>
            {images.slice(1).map((image, index) => (
              <img key={index} src={image} alt={`Active Content ${index}`} className="my-2" />
            ))}
          </div>
        )}
      </div>

      {/* Text and footer section */}
      <div className="mb-20 pr-2 pl-4 sm:pb-0 sm:pr-0 sm:pl-0 sm:w-1/3 flex flex-col justify-between sm:mt-12 ml-4 xl:mt-16 relative hide-scrollbar">
        {/* Text only shows if clicked */}
        <div className={`${clicked ? "" : "opacity-0"}`}>
          <div className="flex flex-col mt-4 xl:mt-2">
            <p>{formatTextWithLineBreaks(text) || ""}</p>
          </div>
        </div>

        {/* Footer */}
        <div className=" mt-10">
          {footer}
        </div>
      </div>
    </div>
  );
};

export default Body;
